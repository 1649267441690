<template>
  <div class="container">
    <div>
      <div class="title">粪便处理</div>
      <div>
        <p style="font-size: 18px; font-weight: 600">固态</p>
        <p>
          宜采用反应器、静态垛式等好氧堆肥技术进行无害化处理，其堆体温度为此50℃以上的时间不少于7
          d，或45℃以上不少于14d。
        </p>
        <p>固体畜禽粪便经过堆肥处理后应符合表1的卫生学要求</p>
        <div style="margin:30px 0">
          <Table :columns="columns1" :data="data1"></Table>
        </div>
      </div>
      <div>
        <p style="font-size: 18px; font-weight: 600">液态</p>
        <p>
      液态畜禽粪便宜采用氧化塘贮存后进行农田利用,或采用固液分离,厌氧发酵﹑好氧或其他生物处理等单一或组合技术进行无害化处理。
        </p>
        <p>
          厌氧发酵可采用常温、中温或高温处理工艺,常温厌氧发酵处理水力停留时间不应少于30
          d,中温厌氧发酵不应少于7 d,高温厌氧发酵温度维持(53士2)℃时间应不少于
          d。厌氧发酵工艺设计应符合NY/T 1220.1的规定,工程设计应符合NY/T
          1222的规定。 
        </p>
        <p>
          经过处理后需要排放的液态部分应符合GB 18596的规定。 
        </p>
        <div style="margin-top:30px">
          <Table :columns="columns2" :data="data2"></Table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      columns1: [
        {
          title: "项目",
          key: "project",
        },
        {
          title: "卫生学要求",
          key: "hygiene",
        },
      ],
      data1: [
        { project: "蛔虫卵", hygiene: "死亡率≥95%" },
        { project: "粪大肠菌群数", hygiene: "≤10⁵个/kg" },
        { project: "苍蝇", hygiene: "堆体周五不应有活的蛆、蛹或新羽化的成蝇" },
      ],
      columns2: [
        {
          title: "项目",
          key: "project",
        },
        {
          title: "卫生学要求",
          key: "hygiene",
        },
      ],
      data2: [
        { project: "蛔虫卵", hygiene: "死亡率≥95%" },
        { project: "钩虫卵", hygiene: "在使用粪液中不应检出活的钩虫卵" },
        {
          project: "粪大肠菌群数",
          hygiene: "常温沼气发酵≤105个/L,高温沼气发酵≤100个/L",
        },
        {
          project: "蚊子、苍蝇",
          hygiene: "粪液中不应有蚊蝇幼虫,池的周围不应有活的蛆,蛹或新羽化的成蝇",
        },
        {
          project: "沼气池粪渣",
          hygiene: "达到表1要求后方可用作农肥",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.container {
  padding: 0 20px;
}
.title {
  font-size: 25px;
  text-align: center;
  font-weight: 600;
}
p{
    line-height: 28px;
}
</style>